.img{
    width: 600px;
    height: 200px;
    object-fit: cover;
}
.hr_project{
    width: 95%;
    margin: auto;
    margin-bottom: 15px;
    
}


.name__project{
    font-size: 30px;
    font-weight: lighter;
    margin-bottom: 0;
    padding: 0;
}

.href__proejct{
    color: #34ae99;
    margin-top: 0;
    margin-bottom: 0;
}

.text__project{
    margin-top: 15px;
}
