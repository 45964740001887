nav{
    background-color: white;
}


.nav-item{
    margin-left: 15px;
}
.links_menu{
    color: black;
    font-weight: 600;
}

.links_menu::after{
    color: black;
    font-weight: 600s;
}

.links_menu:hover{
    color: black;
    font-weight: 600;
}
.dropdown-item:hover{
    background-color: #34ae99;
    color: white;
}

.nav-link:active{
    color: black;
    font-weight: 600s;
}

.icon_menu{
    width: 35px;
    height: 5px;
    background-color: #34ae99;
    margin: 6px 0;
}

.costum_select{
    border: none;
    border-radius: 4px;
}


