.h1_board{
    font-weight: lighter;
    color: #16a78f;
    font-size: 3em;
    word-spacing: 8px;
    padding-bottom: 1em;
    padding-top: 2em;
    margin-bottom: 0;
}
.text__board{
    font-size: 16px;
    line-height: 1.6;
    max-width: 100%;
    word-spacing: 3px;
    padding-bottom: 6em;
    margin: 0;
    font-weight: lighter;
    text-align: center;
}


.h1_managmnet{
    font-weight: lighter;
    color: #16a78f;
    font-size: 3em;
    word-spacing: 8px;
    padding-bottom: 1em;
    padding-top: 2em;
    margin-bottom: 0;
}

.text__mangament{
    font-size: 16px;
    line-height: 1.6;
    max-width: 100%;
    word-spacing: 3px;
    padding-bottom: 6em;
    margin: 0;
    font-weight: lighter;
    text-align: center;
}