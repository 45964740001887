.bname1{
    font-weight: lighter;
    text-align: center;
    margin: auto;
    font-size: 1.8em;
}
.bbottom{
    margin: auto;
    border-bottom: 7px solid #16a78f;
    left: 0;
    bottom: 0;
    width: 20%;
}
.bprof1{
    font-size: 1em;
    margin-top: 15px;
}