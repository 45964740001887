@import url(https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap);
*{
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, body{
  font-family: 'Public Sans', sans-serif;
}

.board-button{
    margin-left: 75px;
}
.menu{
    color: white;
}
.hr__menu{
    background-color: white;
}
nav{
    background-color: white;
}


.nav-item{
    margin-left: 15px;
}
.links_menu{
    color: black;
    font-weight: 600;
}

.links_menu::after{
    color: black;
    font-weight: 600s;
}

.links_menu:hover{
    color: black;
    font-weight: 600;
}
.dropdown-item:hover{
    background-color: #34ae99;
    color: white;
}

.nav-link:active{
    color: black;
    font-weight: 600s;
}

.icon_menu{
    width: 35px;
    height: 5px;
    background-color: #34ae99;
    margin: 6px 0;
}

.costum_select{
    border: none;
    border-radius: 4px;
}



.page-footer{
    position: relative;
    bottom:0;
    width: 100%;
    background-color: #34ae99;
    color: white;
}
.link__footer{
    color: white;
}
.link__footer:hover{
    color: #64FAE1;
}

.social__links{
    width: 40px;
    color: white;
    padding: 10px;
}
.address__footer{
    color: #fff;
    text-align: left;
    display: inline-block;
    font-size: .9rem;
    font-weight: lighter;
    margin-left: .6em;
    margin-bottom: 0;
    line-height: 1.6;
    padding-top: 0 !important;
}
.email__footer{
    color: #fff;
    text-align: left;
    display: inline-block;
    font-size: .9rem;
    font-weight: lighter;
    margin-left: .6em;
    margin-bottom: 0;
    line-height: 1.6;
    padding-top: 0 !important;
}

.load{
    margin-left: 80px;
}
.href_buletin{
    background-color: white;
    padding: 10px;
}
.img-news-dashboard{
    width: 288px;
    height: 192px;
}
.main__home{
    background-color: #5cc2b1;
    color: white;
}
.button__home{
    color: #16a78f;
    border: none;
    background-color:white;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}
.button__home:hover{
    color: #16a78f;
    border: none;
    background-color:white;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}
.home{
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}
.home:hover{
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}
.title__section__home{
    color: #7c7c7c;
    font-weight: lighter;
    text-decoration: unset;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0;
    font-size: 30px;
}
.text__section__home{
    color: #7c7c7c;
    font-weight: lighter;
}
.img_home{
    width: 240px;
    height: 180px;
    object-fit: cover;
}
.home_h3_banner{
    
}


.card-img-top{
    height: 291px;
    width: 291px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}
.card-img-top:hover{
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
}
.member_name{
    text-align: center;
    margin: auto;
}
.descript{
    color: #7c7c7c;
    line-height: 1.6;
    font-size: .8em;
    font-weight: lighter;
}
.img{
    width: 600px;
    height: 200px;
    object-fit: cover;
}
.hr_project{
    width: 95%;
    margin: auto;
    margin-bottom: 15px;
    
}


.name__project{
    font-size: 30px;
    font-weight: lighter;
    margin-bottom: 0;
    padding: 0;
}

.href__proejct{
    color: #34ae99;
    margin-top: 0;
    margin-bottom: 0;
}

.text__project{
    margin-top: 15px;
}

.bname1{
    font-weight: lighter;
    text-align: center;
    margin: auto;
    font-size: 1.8em;
}
.bbottom{
    margin: auto;
    border-bottom: 7px solid #16a78f;
    left: 0;
    bottom: 0;
    width: 20%;
}
.bprof1{
    font-size: 1em;
    margin-top: 15px;
}
.bname{
    font-weight: lighter;
    text-align: center;
    margin: auto;
    font-size: 1.8em;
}
.bprof{
    margin: auto;
    text-align: center;
    color: #7c7c7c;
    font-size: 1em;
    font-weight: lighter;
}
.bbottom{
    margin: auto;
    border-bottom: 7px solid #16a78f;
    left: 0;
    bottom : 0;
    width: 20%;
}
.bprof-1{
    font-size:1em;
    margin-top: 15px;
}
  

.h1_board{
    font-weight: lighter;
    color: #16a78f;
    font-size: 3em;
    word-spacing: 8px;
    padding-bottom: 1em;
    padding-top: 2em;
    margin-bottom: 0;
}
.text__board{
    font-size: 16px;
    line-height: 1.6;
    max-width: 100%;
    word-spacing: 3px;
    padding-bottom: 6em;
    margin: 0;
    font-weight: lighter;
    text-align: center;
}


.h1_managmnet{
    font-weight: lighter;
    color: #16a78f;
    font-size: 3em;
    word-spacing: 8px;
    padding-bottom: 1em;
    padding-top: 2em;
    margin-bottom: 0;
}

.text__mangament{
    font-size: 16px;
    line-height: 1.6;
    max-width: 100%;
    word-spacing: 3px;
    padding-bottom: 6em;
    margin: 0;
    font-weight: lighter;
    text-align: center;
}
.title{
    color: #fff;
    font-weight: lighter;
    text-align: center;
}

.desc{
    font-size: 16px;
    line-height: 1.6;
    word-spacing: 3px;
    margin: 0;
}
.image_news_item{
    width: 700px;
}
.title_news_items{
    font-weight: 300;
    color: #16a78f;
    font-size: 2em;
    word-spacing: 8px;
    padding-bottom: 1em;
}
.icon{
    font-size: 2rem;
    color: blue;
}
.data__news{
    margin-top: 10px;
    margin-bottom: 0px;
    color: #16a78f;
}
.h4__news{
    font-size: calc(1.275rem + .3vw);
    margin-bottom: 0px;
}
.button__news{
    color: white;
    border: none;
    background-color:#16a78f;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}
.button__news:hover{
    color: white;
    border: none;
    background-color:#16a78f;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}

.title_news_items{
    font-weight: 300;
    color: #16a78f;
    font-size: 1.2em;
    word-spacing: 8px;
    padding-bottom: 1em;
}

.img_news{
    
    width: 460px;
    height: 340px;
    object-fit: cover;
 
}

.datanews{
    margin: 0;
    padding-top: 1.2em;
    color: #16a78f
}
.namenews{
    font-size: calc(1.275rem + .3vw);
}
.button_items{
    color: white;
    border: none;
    background-color:#16a78f;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}

.button_items:hover{
    color: white;
    border: none;
    background-color:#16a78f;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}
.hr__items{
    width: 365px;
    margin-left: 7px;
    border-bottom: 2px solid #16a78f;
}
.img-items{
    width: 400px;
    height: 200px;
    object-fit: cover;
}
.title{
    color: #fff;
    font-weight: lighter;
    text-align: center;
    margin-top: 50px;
}

.desc{
    font-size: 16px;
    line-height: 1.6;
    word-spacing: 3px;
    margin: 0;
    color: #fff;
}
.button_buletin{
    margin-left: 40px;
    padding: 2px;
    background-color:#16a78f;
    color: white;
}
.button_buletin:hover{
    margin-left: 40px;
    padding: 2px;
    background-color:#16a78f;
    color: white;
}
