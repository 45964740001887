.page-footer{
    position: relative;
    bottom:0;
    width: 100%;
    background-color: #34ae99;
    color: white;
}
.link__footer{
    color: white;
}
.link__footer:hover{
    color: #64FAE1;
}

.social__links{
    width: 40px;
    color: white;
    padding: 10px;
}
.address__footer{
    color: #fff;
    text-align: left;
    display: inline-block;
    font-size: .9rem;
    font-weight: lighter;
    margin-left: .6em;
    margin-bottom: 0;
    line-height: 1.6;
    padding-top: 0 !important;
}
.email__footer{
    color: #fff;
    text-align: left;
    display: inline-block;
    font-size: .9rem;
    font-weight: lighter;
    margin-left: .6em;
    margin-bottom: 0;
    line-height: 1.6;
    padding-top: 0 !important;
}
