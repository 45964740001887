.button_buletin{
    margin-left: 40px;
    padding: 2px;
    background-color:#16a78f;
    color: white;
}
.button_buletin:hover{
    margin-left: 40px;
    padding: 2px;
    background-color:#16a78f;
    color: white;
}