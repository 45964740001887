.card-img-top{
    height: 291px;
    width: 291px;
    filter: grayscale(100%);
}
.card-img-top:hover{
    filter: grayscale(0%);
}
.member_name{
    text-align: center;
    margin: auto;
}
.descript{
    color: #7c7c7c;
    line-height: 1.6;
    font-size: .8em;
    font-weight: lighter;
}