.datanews{
    margin: 0;
    padding-top: 1.2em;
    color: #16a78f
}
.namenews{
    font-size: calc(1.275rem + .3vw);
}
.button_items{
    color: white;
    border: none;
    background-color:#16a78f;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}

.button_items:hover{
    color: white;
    border: none;
    background-color:#16a78f;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}
.hr__items{
    width: 365px;
    margin-left: 7px;
    border-bottom: 2px solid #16a78f;
}
.img-items{
    width: 400px;
    height: 200px;
    object-fit: cover;
}