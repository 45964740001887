.title{
    color: #fff;
    font-weight: lighter;
    text-align: center;
    margin-top: 50px;
}

.desc{
    font-size: 16px;
    line-height: 1.6;
    word-spacing: 3px;
    margin: 0;
    color: #fff;
}