.bname{
    font-weight: lighter;
    text-align: center;
    margin: auto;
    font-size: 1.8em;
}
.bprof{
    margin: auto;
    text-align: center;
    color: #7c7c7c;
    font-size: 1em;
    font-weight: lighter;
}
.bbottom{
    margin: auto;
    border-bottom: 7px solid #16a78f;
    left: 0;
    bottom : 0;
    width: 20%;
}
.bprof-1{
    font-size:1em;
    margin-top: 15px;
}
  
