.main__home{
    background-color: #5cc2b1;
    color: white;
}
.button__home{
    color: #16a78f;
    border: none;
    background-color:white;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}
.button__home:hover{
    color: #16a78f;
    border: none;
    background-color:white;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}
.home{
    filter: grayscale(100%);
}
.home:hover{
    filter: grayscale(0);
}
.title__section__home{
    color: #7c7c7c;
    font-weight: lighter;
    text-decoration: unset;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0;
    font-size: 30px;
}
.text__section__home{
    color: #7c7c7c;
    font-weight: lighter;
}
.img_home{
    width: 240px;
    height: 180px;
    object-fit: cover;
}
.home_h3_banner{
    
}

