.image_news_item{
    width: 700px;
}
.title_news_items{
    font-weight: 300;
    color: #16a78f;
    font-size: 2em;
    word-spacing: 8px;
    padding-bottom: 1em;
}
.icon{
    font-size: 2rem;
    color: blue;
}
.data__news{
    margin-top: 10px;
    margin-bottom: 0px;
    color: #16a78f;
}
.h4__news{
    font-size: calc(1.275rem + .3vw);
    margin-bottom: 0px;
}
.button__news{
    color: white;
    border: none;
    background-color:#16a78f;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}
.button__news:hover{
    color: white;
    border: none;
    background-color:#16a78f;
    font-weight: bolder;
    font-size: 0.6em;
    margin-bottom: 3em;
    display: block;
    width: 100px;
    cursor: pointer;
    text-align: center;
    padding: 5px;
}

.title_news_items{
    font-weight: 300;
    color: #16a78f;
    font-size: 1.2em;
    word-spacing: 8px;
    padding-bottom: 1em;
}

.img_news{
    
    width: 460px;
    height: 340px;
    object-fit: cover;
 
}
